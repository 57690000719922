import React, { useContext } from 'react';
import AppContext from '@src/context'

// Style Imports
import "./PatientFeedbackWhy.scss"
// Content Imports
import Image from "@components/content/fractals-image.jsx"

const PatientFeedbackWhy = () => {
    const ctx = useContext(AppContext);
    const lang = ctx.currentLang;
    return (
		<section id="PatientFeedbackWhy" className="">
            <div className="container">
                <div className='patient__image'>
                    <Image data={{desktopSrc: 'why-lupkynis/patient-image.png', mobileSrc: 'why-lupkynis/patient-image.png', altText:""}} />
                </div>
                <div className='patient__feedback'>
                    { lang === 'en' && ( 
                        <>
                            <p className='patient__story orange--quotes'>Since starting on <span className='text--orange'>LUPKYNIS</span>,<br className='mobile-only' /> I slowly resumed<br className='desktop-only' /> some of<br className='mobile-only' /> my normal activities I had<br className='mobile-only' /> done prior<br className='desktop-only'/> to my diagnosis.<br className='mobile-only' /> That was such a&nbsp;&nbsp;<span className='font--nortwell'>great thing.</span></p>
                            <p className='patient__name'>—<span>Suriya</span>, started treatment<br className='mobile-only' /> in 2021</p> 
                        </>
                    )}
                    { lang === 'es' && ( 
                        <>
                            <p className='patient__story orange--quotes'>Desde que comencé con <span className='text--orange'>LUPKYNIS</span>,<br className='mobile-only' /> he <br className='desktop-only' />retomado lentamente algunas de<br className='mobile-only' /> mis actividades normales que <br className='mobile-only' /> hacía antes<br className='desktop-only' /> de recibir mi diagnóstico.<br className='desktop-only' /><br className='mobile-only' /> Eso fue &nbsp;<span className='font--nortwell'>algo maravilloso.</span></p>
                            <p className='patient__name'>—<span>Suriya</span>, quien comenzó el tratamiento<br className='mobile-only' />  en 2021</p> 
                        </>
                    )}
                </div>
            </div>
        </section>  
    );
};

export default PatientFeedbackWhy;